import { FC } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps{
    isDisabled?: boolean;
    positionsList: any[];
    value: any[];
    handleChange: (event: any, newValue: readonly any[]) => void;
};

export const AutocompleteCheckBox: FC<IProps> = ({positionsList, isDisabled, value, handleChange}) => {

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={positionsList}
      disableCloseOnSelect
      value={value}
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder="Доступ за посадаю" />
      )}
      disabled={isDisabled}
    />
  );
};