import { FC, useMemo } from "react";
import { useTestCreate } from "../presenters/useTestCreate";
import { Backdrop, Button, Card, CircularProgress, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { getStyles } from "./styles";
import { TimePicker } from "../../../../../ui-kit/timePicker";
import AddIcon from '@mui/icons-material/Add';
import { QuestionModal } from "./components/questionModal";
import { TextInput } from "./components/textInput";
import { BadgeInput } from "./components/badgeInput";
import { AutocompleteSingle } from "./components/autocompleteSingle/index.";
import { AutocompleteMultiline } from "./components/autocompleteMultiline/index.";
import { CheckBox } from "./components/checkBox";
import { QuestionList } from "./components/questionList";
import { CheckboxDepartmentAutocompleteMultiple } from "../../../../../ui-kit/checkboxDepartmentAutocompleteMultiple";
import { SelectCheckBox } from "../../../../../ui-kit/selectCheckbox";
import { AutocompleteCheckBox } from "../../../../../ui-kit/autocompleteCheckBox";

export const TestCreate: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { theme, themeValid, onChangeTheme, orgStructure, groupPermissions, groupPermissionsValid, onChangePermissions, receiver, onChangeReceiver,
        initiator, initiatorValid, onChangeInitiator, onSelectInitiator, publishChecked, handlePublishChange, notifyChecked, handleNotifyChange, handleTest,
        cancelCreate, createClick, deadline, handleChangeDeadline, deadlineValid, attemptAmount, attemptAmountValid, onChangeAttemptAmount, onChangePoints,
        points, onChangeMinPoints, minPoints, minPointsValid, content, onChangeContent, badge, handleChangeBadge, badgesList, changeBadgeText, handleOpenQuestionModal,
        isQuestionModalOpen, questionList, removeQuestion, onDragEnd, defineCurrentQuestion, currentQuestion, handleCloseQuestionModal, testId, contentValid,
        onChangeDepartmentPermissions, departmentPermissions, handleChangePosition, positions, positionsList, onChangeAssignDepartment, assignDepartment,
        handleChangeAssignPosition, assignPositions, onChangeDuration, duration
    } = useTestCreate();

    return (
        <Stack mt={2} ml={2}>
            <Typography variant="h4" fontWeight={600}>{t(tokens.test.createTest)}</Typography>
            <Stack my={5} spacing={3}>
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                    <Stack width="49.5%">
                        <TextInput
                            title={t(tokens.test.theme) + '*'}
                            label={t(tokens.test.theme)}
                            isValid={themeValid}
                            value={theme}
                            isMultiline={false}
                            onChangeValue={onChangeTheme}
                        />
                    </Stack>
                    <Stack width="49%">
                        <AutocompleteSingle
                            testId={false}
                            isValid={initiatorValid}
                            title={t(tokens.test.testInitiator) + '*'}
                            label={t(tokens.test.testInitiator)}
                            value={initiator}
                            orgStructure={orgStructure}
                            onChangeInitiator={onChangeInitiator}
                            onSelectInitiator={onSelectInitiator}
                        />
                    </Stack>
                </Stack>
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                    <Stack width="100%" minWidth="49.2%">
                        <TextInput
                            title={t(tokens.test.shortlyAboutTest)}
                            label={t(tokens.test.shortlyAboutTest)}
                            isValid={contentValid}
                            value={content}
                            isMultiline={true}
                            onChangeValue={onChangeContent}
                        />
                    </Stack>
                    <Stack width="100%" minWidth="50%" spacing={2}>
                        <TextInput
                            title={t(tokens.test.attemptsAmount) + '*'}
                            label={t(tokens.test.attemptsAmount)}
                            isValid={attemptAmountValid}
                            value={attemptAmount}
                            isMultiline={false}
                            onChangeValue={onChangeAttemptAmount}
                        />
                        <TextInput
                            title={t(tokens.test.minPoints) + '*'}
                            label={t(tokens.test.minPoints)}
                            isValid={minPointsValid}
                            value={minPoints}
                            isMultiline={false}
                            onChangeValue={onChangeMinPoints}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Stack width="100%">
                        <BadgeInput
                            title={t(tokens.test.badge)}
                            value={badge}
                            bagesList={badgesList}
                            handleChangeBadge={handleChangeBadge}
                            changeBadgeText={changeBadgeText}
                        />
                    </Stack>
                    <TextInput
                        title={t(tokens.test.points)}
                        label={t(tokens.test.points)}
                        isValid={true}
                        value={points}
                        isMultiline={false}
                        onChangeValue={onChangePoints}
                    />
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} pb={1}>
                    <Stack width="100%" spacing={.5}>
                        <TextInput
                            title={t(tokens.test.duration)}
                            label={t(tokens.test.duration)}
                            isValid={true}
                            value={duration}
                            isMultiline={false}
                            onChangeValue={onChangeDuration}
                        />
                        <Typography color="#F1594E" variant="caption">(для департаментів та посад)</Typography>
                    </Stack>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1} color={deadlineValid ? 'inherit' : 'error'}>
                            {t(tokens.test.deadline)}
                        </Typography>
                        <Stack spacing={.5}>
                            <TimePicker
                                value={deadline}
                                handleDate={handleChangeDeadline}
                            />
                            <Typography color="#F1594E" variant="caption">(для користувачів)</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Card elevation={3} sx={styles.card}>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <AutocompleteMultiline
                            isValid={groupPermissionsValid}
                            title={t(tokens.documents.accessToUser)}
                            label={t(tokens.documents.accessToUser)}
                            value={groupPermissions}
                            orgStructure={orgStructure}
                            onChangePermissions={onChangePermissions}
                        />
                        <Stack width="100%" minWidth="49%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.accessToDepartment)}</Typography>
                            <CheckboxDepartmentAutocompleteMultiple
                                title={t(tokens.documents.accessToDepartment)}
                                value={departmentPermissions}
                                optionList={orgStructure}
                                onChange={onChangeDepartmentPermissions}
                                isDisabled={false}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mb={1}>
                        <Stack width="49.2%" flexShrink={0}>
                            <Typography variant="h6" mb={1}>{t(tokens.documents.accessToPosition)}</Typography>
                            <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} />
                        </Stack>
                        <Stack width="100%" />
                    </Stack>
                </Card>
                <Card elevation={3} sx={styles.card}>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <Stack width="49.5%">
                            <AutocompleteMultiline
                                testId={null}
                                isValid={true}
                                title={t(tokens.documents.assignToUser)}
                                label={t(tokens.documents.assignToUser)}
                                value={receiver}
                                orgStructure={orgStructure}
                                onChangePermissions={onChangeReceiver}
                            />
                        </Stack>
                        <Stack width="49%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToDepartment)}</Typography>
                            <CheckboxDepartmentAutocompleteMultiple
                                title={t(tokens.documents.assignToDepartment)}
                                value={assignDepartment}
                                optionList={orgStructure}
                                onChange={onChangeAssignDepartment}
                                isDisabled={false}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} mb={1}>
                        <Stack width="49.2%" flexShrink={0}>
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToPosition)}</Typography>
                            <AutocompleteCheckBox positionsList={positionsList} value={assignPositions} handleChange={handleChangeAssignPosition} />
                        </Stack>
                        <Stack width="100%" />
                    </Stack>
                </Card>
                <Stack pt={2} alignItems="flex-start">
                    <Button
                        startIcon={<AddIcon />}
                        sx={styles.addQuestionButton}
                        onClick={handleOpenQuestionModal}
                        disabled={!(!!testId)}
                    >
                        {t(tokens.test.addQuestion)}
                    </Button>
                    {!(!!testId) &&
                        <Stack position="relative" left="25px">
                            <Typography variant="caption" color="#F1594E">
                                {t(tokens.test.message.helperTextForAddingQuestion)}
                            </Typography>
                        </Stack>
                    }
                </Stack>
                {questionList.length > 0 &&
                    <Stack mt={2} spacing={1} px={2}>
                        <QuestionList
                            questions={questionList}
                            onDragEnd={onDragEnd}
                            removeQuestion={removeQuestion}
                            defineCurrentQuestion={defineCurrentQuestion}
                        />
                    </Stack>
                }
                <Stack direction='row' mt={3} spacing={3}>
                    <CheckBox
                        isPublish={true}
                        testId={testId}
                        title={t(tokens.documents.publish)}
                        value={publishChecked}
                        onChangeValue={handlePublishChange}
                    />
                    <CheckBox
                        isPublish={true}
                        testId={testId}
                        title={t(tokens.documents.inform)}
                        value={notifyChecked}
                        onChangeValue={handleNotifyChange}
                    />
                </Stack>
                <Stack direction='row' mt={3} spacing={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={handleTest}
                        disabled={createClick}
                    >
                        {!!testId ?
                            t(tokens.common.save)
                            : t(tokens.common.create)
                        }
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={cancelCreate}
                        disabled={createClick}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            </Stack>
            {/* <Backdrop sx={styles.backDrop} open={createClick}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography color="primary" variant="h6">
                        {t(tokens.documents.await)}
                    </Typography>
                </Stack>
            </Backdrop> */}
            <QuestionModal
                testId={testId}
                isOpen={isQuestionModalOpen}
                currentQuestion={currentQuestion}
                onClose={handleCloseQuestionModal}
            />
        </Stack>
    )
};