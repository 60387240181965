import { Button, Card, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import AddIcon from '@mui/icons-material/Add';
import { TextInput } from "../../../tests/testCreate/ui/components/textInput";
import { AutocompleteSingle } from "../../../tests/testCreate/ui/components/autocompleteSingle/index.";
import { AutocompleteMultiline } from "../../../tests/testCreate/ui/components/autocompleteMultiline/index.";
import { CheckBox } from "../../../tests/testCreate/ui/components/checkBox";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";
import { useQuizEdit } from "../presenter/useQuizEdit";
import { QuestionModal } from "./components/questionModal";
import { QuestionList } from "../../../tests/testCreate/ui/components/questionList";
import { CheckboxDepartmentAutocompleteMultiple } from "../../../../../ui-kit/checkboxDepartmentAutocompleteMultiple";
import { SelectCheckBox } from "../../../../../ui-kit/selectCheckbox";
import { paths } from "../../../../../paths";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from "react-router-dom";
import { AutocompleteCheckBox } from "../../../../../ui-kit/autocompleteCheckBox";

export const QuizEdit: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { theme, content, onChangeTheme, onChangeContent, onSelectInitiator, onChangeInitiator, onChangePermissions, groupPermissions, receiver, onChangeReceiver,
        handlePublishChange, handleNotifyChange, themeValid, initiatorValid, groupPermissionsValid, orgStructure, initiator, handleOpenQuestionModal, publishChecked,
        notifyChecked, namelessChecked, handleNamelessChange, handleQuiz, cancelCreate, editClick, isDeleted, id, isQuestionModalOpen, handleCloseQuestionModal,
        currentQuestion, questionList, onDragEnd, removeQuestion, defineCurrentQuestion, onChangeDepartmentPermissions, departmentPermissions,
        positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition, assignPositions } = useQuizEdit();

    return (
        <Stack mt={2} ml={2}>
            <Typography variant="h4" fontWeight={600}>{t(tokens.quiz.editQuiz)}</Typography>
            <Stack my={5} spacing={3}>
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                    <Stack width="100%" minWidth="49%" spacing={2}>
                        <TextInput
                            title={t(tokens.quiz.theme) + '*'}
                            label={t(tokens.quiz.theme) + '*'}
                            isValid={themeValid}
                            value={theme}
                            isMultiline={false}
                            onChangeValue={onChangeTheme}
                            isDisabled={isDeleted}
                        />
                        <AutocompleteSingle
                            testId={isDeleted}
                            isValid={initiatorValid}
                            title={t(tokens.quiz.initiator) + '*'}
                            label={t(tokens.quiz.initiator) + '*'}
                            value={initiator}
                            orgStructure={orgStructure}
                            onChangeInitiator={onChangeInitiator}
                            onSelectInitiator={onSelectInitiator}
                        />
                    </Stack>
                    <Stack width="100%" minWidth="49%">
                        <TextInput
                            title={t(tokens.quiz.shortlyAboutQuiz)}
                            label={t(tokens.quiz.shortlyAboutQuiz)}
                            isValid={true}
                            value={content}
                            isMultiline={true}
                            onChangeValue={onChangeContent}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                </Stack>
                <Card elevation={3} sx={styles.card}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mb={1}>
                        <AutocompleteMultiline
                            isValid={groupPermissionsValid}
                            title={t(tokens.documents.accessToUser)}
                            label={t(tokens.documents.accessToUser)}
                            value={groupPermissions}
                            orgStructure={orgStructure}
                            onChangePermissions={onChangePermissions}
                            isDisabled={isDeleted}
                        />
                        <Stack width="100%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.accessToDepartment)}</Typography>
                            <CheckboxDepartmentAutocompleteMultiple
                                title={t(tokens.documents.accessToDepartment)}
                                value={departmentPermissions}
                                optionList={orgStructure}
                                onChange={onChangeDepartmentPermissions}
                                isDisabled={isDeleted}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <Stack width="49.2%" flexShrink={0}>
                            <Typography variant="h6" mb={1}>{t(tokens.documents.accessToPosition)}</Typography>
                            <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} isDisabled={isDeleted}/>
                        </Stack>
                        <Stack width="100%" pt={5}>
                            <Button
                                variant="contained"
                                sx={styles.editBtn}
                                disabled={editClick || isDeleted}
                                startIcon={<EditOutlinedIcon />}
                                component={Link}
                                to={paths.dashboard.content.quiz.editAccess.replace(":id", String(id))}
                            >
                                {t(tokens.common.editAccess)}
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
                <Card elevation={3} sx={styles.card}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mb={1}>
                        <AutocompleteMultiline
                            testId={null}
                            isValid={true}
                            title={t(tokens.documents.assignToUser)}
                            label={t(tokens.documents.assignToUser)}
                            value={receiver}
                            orgStructure={orgStructure}
                            onChangePermissions={onChangeReceiver}
                            isDisabled={isDeleted}
                        />
                        <Stack width="100%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToDepartment)}</Typography>
                            <CheckboxDepartmentAutocompleteMultiple
                                title={t(tokens.documents.assignToDepartment)}
                                value={assignDepartment}
                                optionList={orgStructure}
                                onChange={onChangeAssignDepartment}
                                isDisabled={isDeleted}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <Stack width="49.2%" flexShrink={0}>
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToPosition)}</Typography>
                            <AutocompleteCheckBox positionsList={positionsList} value={assignPositions} handleChange={handleChangeAssignPosition} isDisabled={isDeleted}/>
                        </Stack>
                        <Stack width="100%" pt={5}>
                            <Button
                                variant="contained"
                                sx={styles.editBtn}
                                disabled={editClick || isDeleted}
                                startIcon={<EditOutlinedIcon />}
                                component={Link}
                                to={paths.dashboard.content.quiz.editAssign.replace(":id", String(id))}
                            >
                                {t(tokens.common.editAssign)}
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
                <Stack pt={2} alignItems="flex-start">
                    <Button
                        startIcon={<AddIcon />}
                        sx={styles.addQuestionButton}
                        onClick={handleOpenQuestionModal}
                        disabled={isDeleted}
                    >
                        {t(tokens.quiz.addQuestion)}
                    </Button>
                </Stack>
                {questionList.length > 0 &&
                    <Stack mt={2} spacing={1} px={2}>
                        <QuestionList
                            isDisabled={isDeleted}
                            questions={questionList}
                            onDragEnd={onDragEnd}
                            removeQuestion={removeQuestion}
                            defineCurrentQuestion={defineCurrentQuestion}
                        />
                    </Stack>
                }
                <Stack direction='row' mt={3} spacing={3}>
                    <CheckBox
                        isPublish={true}
                        testId={true}
                        title={t(tokens.quiz.publish)}
                        value={publishChecked}
                        onChangeValue={handlePublishChange}
                        isDisabled={isDeleted}
                    />
                    <CheckBox
                        isPublish={false}
                        testId={null}
                        title={t(tokens.quiz.inform)}
                        value={notifyChecked}
                        onChangeValue={handleNotifyChange}
                        isDisabled={isDeleted}
                    />
                    <CheckBox
                        isPublish={false}
                        testId={null}
                        title={t(tokens.quiz.nameLessQuiz)}
                        value={namelessChecked}
                        onChangeValue={handleNamelessChange}
                        isDisabled={true}
                    />
                </Stack>
                {!isDeleted &&
                    <Stack direction='row' mt={3} spacing={3}>
                        <Button
                            variant="contained"
                            sx={styles.createButton}
                            onClick={handleQuiz}
                            disabled={editClick}
                        >
                            {t(tokens.common.save)}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={styles.cancelButton}
                            onClick={cancelCreate}
                            disabled={editClick}
                        >
                            {t(tokens.common.cancel)}
                        </Button>
                    </Stack>}
            </Stack>
            <QuestionModal
                quizId={id}
                isOpen={isQuestionModalOpen}
                currentQuestion={currentQuestion}
                onClose={handleCloseQuestionModal}
            />
        </Stack>
    )
}