export function transformTable(text: string): string {
    const tableRegex = /<p>\s*<table><br>Заголовок: (.*?)<br>(.*?)<br><\/table><\/p>/gs;
    let updatedHtml = text;
    let tableCounter = 1;

    let match;
    while ((match = tableRegex.exec(text)) !== null) {
        const [fullMatch, title, rowsText] = match;

        let rows = rowsText.replace(/<br>\s*/g, ' ').split(/\s*Рядок:\s*/).filter(row => row?.trim() !== '');
        const tableRowsHtml = rows.map(row => {
            const cells = row.trim().split(/\s*\|\s*/).map(cell => `<td>${cell}</td>`).join('');
            return `<tr>${cells}</tr>`;
        }).join('');

        const tableId = `data-table-${tableCounter}`;
        const newHtml = `
        <p></p>
        <toggle-table>
            <p onclick="toggleTable()" style="cursor: pointer; color: blue; text-decoration: underline;">${title}</p>
            <table id="${tableId}" style="display: none; margin-top: 20px; border-collapse: collapse; width: 100%; border: 1px solid black;">
                    ${tableRowsHtml}
            </table>
        </toggle-table>
        <p></p>
        `;

        updatedHtml = updatedHtml.replace(fullMatch, newHtml);
        tableCounter++;
    }

    return updatedHtml;
};

export function extractTablesFromHtml(htmlContent: string): string {
    const tableRegex = /<toggle-table>\s*<p onclick="toggleTable\(\)" style="cursor: pointer; color: blue; text-decoration: underline;">(.*?)<\/p>\s*<table id="(.*?)"[^>]*>(.*?)<\/table>\s*<\/toggle-table>/gs;
    let restoredHtml = htmlContent;
    let match;
    while ((match = tableRegex.exec(htmlContent)) !== null) {
        const [fullMatch, title, tableId, tableContent] = match;

        const reconstructedTable = `
        <table>
            <br>Заголовок: ${title}<br>
            Рядок: ${tableContent}
            <br>
        </table>
        `;

        restoredHtml = restoredHtml.replace(fullMatch, reconstructedTable);
    }

    return restoredHtml;
};

export function transformHtml(htmlText: string): string {
    const tableRegex = /<p><\/p>\s*<table>[\s\S]*?<\/table>\s*<p><\/p>/gs;
    let transformedHtml = htmlText;
    let match;

    while ((match = tableRegex.exec(htmlText)) !== null) {
        const tableHtml = match[0];
        const rowsRegex = /<tr>(.*?)<\/tr>/gs; 

        const titleRegex = /<br>Заголовок:\s*(.*?)<br>/;
        const titleMatch = tableHtml.match(titleRegex);
        const title = titleMatch ? titleMatch[1] : "Назва";

        const rows = tableHtml.match(rowsRegex);
        if (rows) {
            const parsedRows = rows.map(row => {
                const cellsRegex = /<td>(.*?)<\/td>/gs;
                const cells = row.match(cellsRegex);

                if (cells) {
                    const formattedCells = cells.map(cell => {
                        return cell.replace(/<\/?td>/g, ''); 
                    }).join(' | ');

                    return `Рядок: ${formattedCells}`;
                } else {
                    return ''; 
                }
            }).join(' <br>');

            const finalResult = `<p>&lt;table&gt;<br>Заголовок: ${title}<br>${parsedRows}<br>&lt;/table&gt;</p>`;
            transformedHtml = transformedHtml.replace(tableHtml, finalResult);
        }
    }
    transformedHtml = transformedHtml.replace(/<p>\s*<\/p>\s*<p><table>/g, '<p><table>');
    transformedHtml = transformedHtml.replace(/<\/table><\/p>\s*<p>\s*<\/p>/g, '</table></p>');

    return transformedHtml;
};

export function ensureParagraphTag(text: string) {
    const paragraphTag = '<p></p>';
    if (text.startsWith(paragraphTag)) {
        return text;
    } else {
        return paragraphTag + text;
    }
};