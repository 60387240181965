import { FC, useMemo } from "react";
import { useAdvertEdit } from "../presentrs/useAdverEdit";
import { Backdrop, Button, Card, Checkbox, CircularProgress, FormControlLabel, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { ItemSelector } from "../../../../ui-kit/itemSelector/itemSelector";
import { CheckboxAutocompleteSingle } from "../../../../ui-kit/checkboxAutocompleteSingle";
import { CheckboxAutocompleteMultiple } from "../../../../ui-kit/checkboxAutocompleteMultiple";
import { getStyles } from "./styles";
import TextEditor from "../../../../ui-kit/textEditor/textEditor";
import { ImagePicker } from "./components/imagePicker";
import { DateAndTimePicker } from "../../../../ui-kit/dateTimePicker";
import { CheckboxDepartmentAutocompleteMultiple } from "../../../../ui-kit/checkboxDepartmentAutocompleteMultiple";
import { SelectCheckBox } from "../../../../ui-kit/selectCheckbox";
import { Link } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { paths } from "../../../../paths";
import { AutocompleteCheckBox } from "../../../../ui-kit/autocompleteCheckBox";

export const AdvertEdit: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { advertTypeOptions, type, changeType, theme, onChangeTheme, initiator, onChangeInitiator, onSelectInitiator, groupPermissions,
        onChangePermissions, orgStructure, onChangeReceiver, receiver, publishChecked, notifyChecked, handlePublishChange, isDeleted,
        handleNotifyChange, editAdvert, cancelEdit, handleFileChange, imageSrc, link, onChangeLink, startDate, upToDate, editClick,
        handleStartDateChange, handleUpToDateChange, typeValid, themeValid, imageValid, initiatorValid, receiverValid, singleAdvert,
        startDateValid, upToDateValid, handleClearImageClick, editorState, onEditorStateChange, setEditorState, contentValid,
        onChangeDepartmentPermissions, departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment,
        handleChangeAssignPosition, assignPositions, id } = useAdvertEdit();

    return (
        <Stack m={2} mb={4}>
            <Typography variant="h4" fontWeight={600}>{t(tokens.adverts.editAdvert)}</Typography>
            <Stack mt={3} direction="row" justifyContent="flex-start" spacing={10}>
                <Stack width="50%" mt={3} pr={1}>
                    <Stack>
                        <Typography variant="h6" mb={1} color={typeValid ? 'inherit' : 'error'}>{t(tokens.documents.type)}*</Typography>
                        <ItemSelector
                            label={t(tokens.adverts.type)}
                            value={type}
                            isDisabled={true}
                            sortOptions={advertTypeOptions}
                            onChange={changeType}
                        />
                    </Stack>
                    <Stack mt={2}>
                        <Typography variant="h6" mb={1} color={themeValid ? 'inherit' : 'error'}>{t(tokens.adverts.theme)}*</Typography>
                        <TextField
                            fullWidth
                            value={theme}
                            onChange={onChangeTheme}
                            label={t(tokens.adverts.theme)}
                            autoComplete="off"
                            disabled={isDeleted}
                        />
                    </Stack>
                </Stack>
                <Stack>
                    <ImagePicker
                        isDisabled={isDeleted}
                        imageSrc={imageSrc}
                        isValid={imageValid}
                        handleFileChange={handleFileChange}
                        handleClearImageClick={handleClearImageClick}
                    />
                </Stack>
            </Stack>
            <Stack mt={2}>
                <Typography variant="h6" mb={1} color={contentValid ? 'inherit' : 'error'}>
                    {t(tokens.documents.content)}*
                </Typography>
                <TextEditor
                    disabled={isDeleted}
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                />
            </Stack>
            <Stack width="50%" my={2} pr={1}>
                <Typography variant="h6" mb={1} color={initiatorValid ? 'inherit' : 'error'}>
                    {t(tokens.adverts.initiator)}*
                </Typography>
                <CheckboxAutocompleteSingle
                    title={t(tokens.adverts.initiator)}
                    value={initiator}
                    optionList={orgStructure}
                    onSelect={onSelectInitiator}
                    onChange={onChangeInitiator}
                    isValid={initiatorValid}
                    isDisabled={isDeleted}
                />
            </Stack>
            <Card elevation={3} sx={styles.card}>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" mt={2}>
                        <Typography variant="h6" mb={1} color={receiverValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.accessToUser)}
                        </Typography>
                        <CheckboxAutocompleteMultiple
                            title={t(tokens.documents.accessToUser)}
                            value={groupPermissions}
                            optionList={orgStructure}
                            onChange={onChangePermissions}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                    <Stack width="49%" mb={1}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.accessToDepartment)}</Typography>
                        <CheckboxDepartmentAutocompleteMultiple
                            title={t(tokens.documents.accessToDepartment)}
                            value={departmentPermissions}
                            optionList={orgStructure}
                            onChange={onChangeDepartmentPermissions}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" flexShrink={0}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.accessToPosition)}</Typography>
                        <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} isDisabled={isDeleted}/>
                    </Stack>
                    <Stack width="100%" pt={5}>
                        <Button
                            variant="contained"
                            sx={styles.editBtn}
                            disabled={editClick || isDeleted}
                            startIcon={<EditOutlinedIcon />}
                            component={Link}
                            to={paths.dashboard.advert.editAccess.replace(":id", String(id))}
                        >
                            {t(tokens.common.editAccess)}
                        </Button>
                    </Stack>
                </Stack>
            </Card>
            <Card elevation={3} sx={styles.card}>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" mt={2}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToUser)}</Typography>
                        <CheckboxAutocompleteMultiple
                            title={t(tokens.documents.assignToUser)}
                            value={receiver}
                            optionList={orgStructure}
                            onChange={onChangeReceiver}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                    <Stack width="49%">
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToDepartment)}</Typography>
                        <CheckboxDepartmentAutocompleteMultiple
                            title={t(tokens.documents.assignToDepartment)}
                            value={assignDepartment}
                            optionList={orgStructure}
                            onChange={onChangeAssignDepartment}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" flexShrink={0}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToPosition)}</Typography>
                        <AutocompleteCheckBox positionsList={positionsList} value={assignPositions} handleChange={handleChangeAssignPosition} isDisabled={isDeleted}/>
                    </Stack>
                    <Stack width="100%" pt={5}>
                        <Button
                            variant="contained"
                            sx={styles.editBtn}
                            disabled={editClick || isDeleted}
                            startIcon={<EditOutlinedIcon />}
                            component={Link}
                            to={paths.dashboard.advert.editAssign.replace(":id", String(id))}
                        >
                            {t(tokens.common.editAssign)}
                        </Button>
                    </Stack>
                </Stack>
            </Card>
            {type === "News" ?
                <Stack width="50%" mt={3} pr={1}>
                    <Typography variant="h6" mb={1}>{t(tokens.documents.link)}</Typography>
                    <TextField
                        fullWidth
                        value={link}
                        onChange={onChangeLink}
                        label={t(tokens.documents.link)}
                        autoComplete="off"
                        type="url"
                        disabled={isDeleted}
                    />
                </Stack>
                :
                null
            }
            {type === "Event" ?
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                    <Stack flex={1}>
                        <Typography variant="h6" mb={1} color={startDateValid ? 'inherit' : 'error'}>{t(tokens.adverts.startEvent)}*</Typography>
                        <DateAndTimePicker value={startDate} handleDate={handleStartDateChange} title={t(tokens.adverts.startEvent)} isDisabled={isDeleted} />
                    </Stack>
                    <Stack flex={1}>
                        <Typography variant="h6" mb={1} color={upToDateValid ? 'inherit' : 'error'}>{t(tokens.adverts.endEvent)}*</Typography>
                        <DateAndTimePicker value={upToDate} handleDate={handleUpToDateChange} title={t(tokens.adverts.endEvent)} isDisabled={isDeleted} />
                    </Stack>
                </Stack>
                :
                null
            }
            <Stack direction='row' mt={3} spacing={3}>
                <FormControlLabel control={<Checkbox checked={publishChecked} onChange={handlePublishChange} disabled={isDeleted} />} label={t(tokens.documents.publish)} />
                <FormControlLabel control={<Checkbox checked={notifyChecked} onChange={handleNotifyChange} disabled={isDeleted} />} label={t(tokens.documents.inform)} />
            </Stack>
            {!isDeleted &&
                <Stack direction='row' mt={3} spacing={3}>
                    <Button variant="contained" sx={styles.createButton} onClick={editAdvert} disabled={editClick}>
                        {t(tokens.common.save)}
                    </Button>
                    <Button variant="outlined" sx={styles.cancelButton} onClick={cancelEdit} disabled={editClick}>
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>}
            <Backdrop sx={styles.backDrop} open={editClick}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography color="primary" variant="h6">
                        {t(tokens.documents.await)}
                    </Typography>
                </Stack>
            </Backdrop>
        </Stack>
    )
};