import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { Box, Pagination, Stack, Table, Theme, useMediaQuery } from "@mui/material";
import { getStyles } from "./styles";
import { PositionTableBody } from "../positionTableBody";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    positionList: any[];
    page: number;
    pageAmount: number;
    handleDelete: (id: number) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const PositionTable: FC<IProps> = ({ handlePageChange, pageAmount, page, handleDelete, positionList, scrollRef }) => {
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);

    return (
        <>
            <Box mt={4}>
                <Box /* sx={styles.tableWrapper} */ ref={scrollRef}>
                    <Table>
                        <PositionTableBody
                            positionList={positionList}
                            handleDelete={handleDelete}
                        />
                    </Table>
                </Box>
                {/* <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                    <Pagination
                        page={page}
                        count={pageAmount || 1}
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack> */}
            </Box>
        </>
    )
};