import { FC, useMemo } from "react";
import { useAdvertCreate } from "../presentrs/useAdverCreate";
import { Backdrop, Button, Card, Checkbox, CircularProgress, FormControlLabel, Paper, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { ItemSelector } from "../../../../ui-kit/itemSelector/itemSelector";
import { CheckboxAutocompleteSingle } from "../../../../ui-kit/checkboxAutocompleteSingle";
import { CheckboxAutocompleteMultiple } from "../../../../ui-kit/checkboxAutocompleteMultiple";
import { getStyles } from "./styles";
import TextEditor from "../../../../ui-kit/textEditor/textEditor";
import { ImagePicker } from "./components/imagePicker";
import { DateAndTimePicker } from "../../../../ui-kit/dateTimePicker";
import { CheckboxDepartmentAutocompleteMultiple } from "../../../../ui-kit/checkboxDepartmentAutocompleteMultiple";
import { SelectCheckBox } from "../../../../ui-kit/selectCheckbox";
import { AutocompleteCheckBox } from "../../../../ui-kit/autocompleteCheckBox";

export const AdvertCreate: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { advertTypeOptions, type, changeType, theme, onChangeTheme, initiator, onChangeInitiator, onSelectInitiator, groupPermissions,
        onChangePermissions, orgStructure, onChangeReceiver, receiver, publishChecked, notifyChecked, handlePublishChange,
        handleNotifyChange, createAdvert, cancelCreate, handleFileChange, imageSrc, link, onChangeLink, startDate, upToDate,
        handleStartDateChange, handleUpToDateChange, typeValid, themeValid, imageValid, initiatorValid, receiverValid, createClick,
        startDateValid, upToDateValid, handleClearImageClick, editorState, setEditorState, onEditorStateChange, contentValid,
        onChangeDepartmentPermissions, departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment,
        assignDepartment, handleChangeAssignPosition, assignPositions
    } = useAdvertCreate();

    return (
        <Stack m={2} mb={4}>
            <Typography variant="h4" fontWeight={600}>{t(tokens.adverts.createAdvert)}</Typography>
            <Stack mt={3} direction="row" justifyContent="flex-start" spacing={10}>
                <Stack width="50%" mt={3} pr={1}>
                    <Stack >
                        <Typography variant="h6" mb={1} color={typeValid ? 'inherit' : 'error'}>{t(tokens.documents.type)}*</Typography>
                        <ItemSelector
                            label={t(tokens.adverts.type)}
                            value={type}
                            isDisabled={false}
                            sortOptions={advertTypeOptions}
                            onChange={changeType}
                        />
                    </Stack>
                    <Stack mt={2}>
                        <Typography variant="h6" mb={1} color={themeValid ? 'inherit' : 'error'}>{t(tokens.adverts.theme)}*</Typography>
                        <TextField
                            fullWidth
                            value={theme}
                            onChange={onChangeTheme}
                            label={t(tokens.adverts.theme)}
                            autoComplete="off"
                        />
                    </Stack>
                </Stack>
                <Stack>
                    <ImagePicker
                        imageSrc={imageSrc}
                        isValid={imageValid}
                        handleFileChange={handleFileChange}
                        handleClearImageClick={handleClearImageClick}
                    />
                </Stack>
            </Stack>
            <Stack mt={2}>
                <Typography variant="h6" mb={1} color={contentValid ? 'inherit' : 'error'}>
                    {t(tokens.documents.content)}*
                </Typography>
                <TextEditor
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                />
            </Stack>
            <Stack width="50%" my={2} pr={1}>
                <Typography variant="h6" mb={1} color={initiatorValid ? 'inherit' : 'error'}>{t(tokens.adverts.initiator)}*</Typography>
                <CheckboxAutocompleteSingle
                    title={t(tokens.adverts.initiator)}
                    value={initiator}
                    optionList={orgStructure}
                    onSelect={onSelectInitiator}
                    onChange={onChangeInitiator}
                    isValid={initiatorValid}
                    isDisabled={false}
                />
            </Stack>
            <Card elevation={3} sx={styles.card}>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" mt={2}>
                        <Typography variant="h6" mb={1} color={receiverValid ? 'inherit' : 'error'}>{t(tokens.documents.accessToUser)}</Typography>
                        <CheckboxAutocompleteMultiple
                            title={t(tokens.documents.accessToUser)}
                            value={groupPermissions}
                            optionList={orgStructure}
                            onChange={onChangePermissions}
                            isDisabled={false}
                        />
                    </Stack>
                    <Stack width="49%">
                        <Typography variant="h6" mb={1}>{t(tokens.documents.accessToDepartment)}</Typography>
                        <CheckboxDepartmentAutocompleteMultiple
                            title={t(tokens.documents.accessToDepartment)}
                            value={departmentPermissions}
                            optionList={orgStructure}
                            onChange={onChangeDepartmentPermissions}
                            isDisabled={false}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" flexShrink={0}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.accessToPosition)}</Typography>
                        <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} />
                    </Stack>
                    <Stack width="49%" />
                </Stack>
            </Card>
            <Card elevation={3} sx={styles.card}>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" mt={2}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToUser)}</Typography>
                        <CheckboxAutocompleteMultiple
                            title={t(tokens.documents.assignToUser)}
                            value={receiver}
                            optionList={orgStructure}
                            onChange={onChangeReceiver}
                            isDisabled={false}
                        />
                    </Stack>
                    <Stack width="49%">
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToDepartment)}</Typography>
                        <CheckboxDepartmentAutocompleteMultiple
                            title={t(tokens.documents.assignToDepartment)}
                            value={assignDepartment}
                            optionList={orgStructure}
                            onChange={onChangeAssignDepartment}
                            isDisabled={false}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" flexShrink={0}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToPosition)}</Typography>
                        <AutocompleteCheckBox positionsList={positionsList} value={assignPositions} handleChange={handleChangeAssignPosition} />
                    </Stack>
                    <Stack width="49%"/>
                </Stack>
            </Card>
            {type === "News" ?
                <Stack width="50%" mt={3} pr={1}>
                    <Typography variant="h6" mb={1}>{t(tokens.documents.link)}</Typography>
                    <TextField
                        fullWidth
                        value={link}
                        onChange={onChangeLink}
                        label={t(tokens.documents.link)}
                        autoComplete="off"
                        type="url"
                    />
                </Stack>
                :
                null
            }
            {type === "Event" ?
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                    <Stack flex={1}>
                        <Typography variant="h6" mb={1} color={startDateValid ? 'inherit' : 'error'}>{t(tokens.adverts.startEvent)}*</Typography>
                        <DateAndTimePicker value={startDate} handleDate={handleStartDateChange} title={t(tokens.adverts.startEvent)} />
                    </Stack>
                    <Stack flex={1}>
                        <Typography variant="h6" mb={1} color={upToDateValid ? 'inherit' : 'error'}>{t(tokens.adverts.endEvent)}*</Typography>
                        <DateAndTimePicker value={upToDate} handleDate={handleUpToDateChange} title={t(tokens.adverts.endEvent)} />
                    </Stack>
                </Stack>
                :
                null
            }
            <Stack direction='row' mt={3} spacing={3}>
                <FormControlLabel control={<Checkbox checked={publishChecked} onChange={handlePublishChange} />} label={t(tokens.documents.publish)} />
                <FormControlLabel control={<Checkbox checked={notifyChecked} onChange={handleNotifyChange} />} label={t(tokens.documents.inform)} />
            </Stack>
            <Stack direction='row' mt={3} spacing={3}>
                <Button variant="contained" sx={styles.createButton} onClick={createAdvert} disabled={createClick}>
                    {t(tokens.common.create)}
                </Button>
                <Button variant="outlined" sx={styles.cancelButton} onClick={cancelCreate} disabled={createClick}>
                    {t(tokens.common.cancel)}
                </Button>
            </Stack>
            <Backdrop sx={styles.backDrop} open={createClick}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography color="primary" variant="h6">
                        {t(tokens.documents.await)}
                    </Typography>
                </Stack>
            </Backdrop>
        </Stack>
    )
};